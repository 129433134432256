import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { Alert, Form, Input } from 'antd';
import { useAppDispatch, useAppSelector } from '@hooks/useStore';
import strings from '@resources/localization';
import { modalAction } from '@state/reducers/Modal/ModalReducer';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { authUpdateEmail } from '@state/actions';
import { useRequestState } from '@api/requests/AsyncRequests';
import { InformationModal } from './information_modal/InformationModal';
import { FormEmailItem } from '@components/form/FormEmailItem/FormEmailItem';
import { FooterModal } from './components/FooterModal';
import { TuringoModal } from '@components/modals/components/TuringoModal/TuringoModal';
export const EmailChangeModal = () => {
    const emailModal = useAppSelector((state) => state.modal.email);
    const dispatch = useAppDispatch();
    const [form] = Form.useForm();
    const closeEmailModal = () => {
        dispatch(modalAction({ modal: 'email', state: false }));
    };
    const changeComplete = () => {
        closeEmailModal();
        setInfoModal(true);
    };
    const [infoModal, setInfoModal] = useState(false);
    const [state, setState] = useRequestState(undefined, false, false);
    const [errorMessage, setErrorMessage] = useState(undefined);
    const onSubmit = (values) => {
        void dispatch(authUpdateEmail({
            bodyParams: {
                email: values.email,
                password: values.password,
            },
            options: {
                redux: {
                    stateHandler: setState,
                },
                then: {
                    action: changeComplete,
                },
            },
        }));
    };
    useEffect(() => {
        if (state.error == 'application.account.password.mismatch') {
            setErrorMessage(strings.screens.settings.sections.signup.changeEmail.modal.errors.passwordMismatch);
        }
        else if (state.error == 'application.account.email.used') {
            setErrorMessage(strings.screens.settings.sections.signup.changeEmail.modal.errors.emailUsed);
        }
        else {
            setErrorMessage(strings.screens.settings.sections.signup.changeEmail.modal.errors.general);
        }
    }, [state.error]);
    return (_jsxs(_Fragment, { children: [_jsx(InformationModal, { kind: 'success', open: infoModal, onClick: () => setInfoModal(false), title: strings.screens.settings.sections.signup.changeEmail.success.title, description: strings.screens.settings.sections.signup.changeEmail.success.description }), _jsxs(TuringoModal, { width: 572, title: strings.screens.settings.sections.signup.changeEmail.modal.title, open: emailModal, onCancel: closeEmailModal, footer: [
                    _jsx(FooterModal, { primary: {
                            action: () => {
                                form.validateFields()
                                    .then((values) => {
                                    form.resetFields();
                                    onSubmit(values);
                                })
                                    .catch(() => { });
                            },
                            customText: strings.screens.settings.sections.signup.changeEmail.modal.actions.accept,
                            loading: state.editing,
                        }, secondary: {
                            action: closeEmailModal,
                            customText: strings.screens.settings.sections.signup.changeEmail.modal.actions.cancel,
                        } }, 'footer_modal'),
                ], children: [_jsxs("p", { children: [strings.screens.settings.sections.signup.changeEmail.modal.description, " "] }), _jsxs(Form, { form: form, layout: 'vertical', children: [_jsx(FormEmailItem, { name: 'email', label: strings.screens.settings.sections.signup.changeEmail.modal.email.label, validations: ['format', 'required'], placeholder: strings.screens.settings.sections.signup.changeEmail.modal.email.placeholder }), _jsx(Form.Item, { name: "password", label: strings.screens.settings.sections.signup.changeEmail.modal.password.label, rules: [{ required: true, message: strings.forms.password.validations.required }], children: _jsx(Input.Password, { type: "password", placeholder: strings.screens.settings.sections.signup.changeEmail.modal.password.placeholder, autoComplete: "off", iconRender: (visible) => (visible ? _jsx(EyeTwoTone, {}) : _jsx(EyeInvisibleOutlined, {})) }) }), state.error && _jsx(Alert, { showIcon: true, style: { marginBottom: 10 }, message: errorMessage, type: "error", closable: true })] })] })] }));
};
