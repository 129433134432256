import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useContext, useEffect, useState } from 'react';
import { Alert, Card, Checkbox, Form, Radio, Row } from 'antd';
import strings from '@resources/localization';
import { RatioImage } from '@components/image/RatioImage';
import Paragraph from 'antd/lib/typography/Paragraph';
import { TuringoButton } from 'src/turingo_components/buttons/button/TuringoButton';
import { FormContext } from 'antd/es/form/context';
const QuestionFormItem = (props) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
    const { question, index, quiz, errors, quizComplete, submitError } = props;
    const { form } = useContext(FormContext);
    const value = Form.useWatch([question.id], form);
    const [firstRender, setFirstRender] = useState(true);
    const showCorrectAnswers = quiz.options.showCorrectAnswers;
    const showResults = quiz.options.showResults;
    let isCorrect = false;
    const answer = (_c = (_b = (_a = quiz.pages) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.questions.find((q) => q.id == question.id)) === null || _c === void 0 ? void 0 : _c.answer;
    if (question.kind == 'choice.multiple') {
        isCorrect = ((_d = answer === null || answer === void 0 ? void 0 : answer.sort()) === null || _d === void 0 ? void 0 : _d.join(',')) === ((_e = value === null || value === void 0 ? void 0 : value.sort()) === null || _e === void 0 ? void 0 : _e.join(','));
    }
    else {
        isCorrect = answer == value;
    }
    useEffect(() => {
        if (firstRender) {
            setFirstRender(false);
            return;
        }
        else {
            form.validateFields([question.id])
                .then((values) => {
                const tempErrors = [...errors.get];
                const index = errors.get.findIndex((error) => { var _a; return (_a = error === null || error === void 0 ? void 0 : error.name) === null || _a === void 0 ? void 0 : _a.includes(question.id); });
                if (index >= 0) {
                    tempErrors.splice(index, 1);
                }
                submitError.set(false);
                errors.set(tempErrors);
            })
                .catch((error) => {
                const tempErrors = [...errors.get];
                const index = tempErrors.findIndex((error) => { var _a; return (_a = error === null || error === void 0 ? void 0 : error.name) === null || _a === void 0 ? void 0 : _a.includes(question.id); });
                if (index >= 0) {
                    tempErrors.splice(index, 1);
                }
                if (error.errorFields) {
                    errors.set([...tempErrors, ...error.errorFields]);
                }
                else {
                    errors.set(tempErrors.filter((error) => !!error));
                }
            });
        }
    }, [value]);
    const hasError = ((_g = (_f = errors.get.find((error) => { var _a; return (_a = error === null || error === void 0 ? void 0 : error.name) === null || _a === void 0 ? void 0 : _a.includes(question.id); })) === null || _f === void 0 ? void 0 : _f.errors) === null || _g === void 0 ? void 0 : _g.length) > 0;
    const label = (_jsxs("div", { style: { display: 'flex', flexDirection: 'column', width: '100%' }, children: [_jsx(Paragraph, { className: "turingo-text turingo-required", style: { marginBottom: 8 }, strong: true, children: question.title }), showResults && quizComplete && (_jsx(Alert, { showIcon: true, type: isCorrect ? 'success' : 'error', message: isCorrect ? strings.screens.admin.quizzes.rightAnswer : strings.screens.admin.quizzes.wrongAnswer, style: {
                    borderRadius: 4,
                    marginBottom: 16,
                } })), question.assetUrl && _jsx(RatioImage, { style: { marginBottom: 16 }, src: question.assetUrl })] }));
    if (question.kind == 'choice.multiple') {
        return (_jsxs("div", { style: { marginBottom: 16 }, children: [_jsxs(Card, { style: {
                        transition: 'all 0.3s',
                        borderColor: hasError ? 'red' : 'var(--border)',
                    }, bodyStyle: { padding: 16 }, children: [_jsx("div", { style: { marginBottom: 8 }, className: "turingo-secondary", children: strings.formatString(strings.screens.evaluation.quiz.progress, index + 1, (_j = (_h = quiz.pages) === null || _h === void 0 ? void 0 : _h[0]) === null || _j === void 0 ? void 0 : _j.questions.length) }), _jsx(Form.Item, { className: "quiz-form-item", requiredMark: false, required: false, style: { marginBottom: 0 }, name: [question.id], label: label, rules: [{ required: true, message: '' }], children: _jsx(Checkbox.Group, { children: (_k = question === null || question === void 0 ? void 0 : question.alternatives) === null || _k === void 0 ? void 0 : _k.map((alternative, alternativeIndex) => {
                                    var _a;
                                    return (_jsx("div", { style: {
                                            display: 'flex',
                                            alignItems: 'flex-start',
                                            marginBottom: alternativeIndex !== ((_a = question === null || question === void 0 ? void 0 : question.alternatives) === null || _a === void 0 ? void 0 : _a.length) - 1 ? 8 : 0,
                                            width: '100%',
                                        }, children: _jsx(Checkbox, { className: showCorrectAnswers && (answer === null || answer === void 0 ? void 0 : answer.includes(alternative.id))
                                                ? (answer === null || answer === void 0 ? void 0 : answer.includes(value === null || value === void 0 ? void 0 : value.find((v) => v == alternative.id)))
                                                    ? 'correct'
                                                    : 'incorrect'
                                                : quizComplete
                                                    ? 'none'
                                                    : '', value: alternative.id, children: _jsx(Paragraph, { className: "turingo-text", children: alternative.title }) }, alternativeIndex) }, alternativeIndex));
                                }) }) })] }), _jsx(Paragraph, { style: { color: 'red', transition: 'all 0.3s' }, children: hasError ? strings.screens.evaluation.quiz.error.empty : '' })] }));
    }
    if (question.kind == 'choice.single') {
        return (_jsxs("div", { style: { marginBottom: 16 }, children: [_jsxs(Card, { style: {
                        transition: 'all 0.3s',
                        borderColor: hasError ? 'red' : 'var(--border)',
                    }, bodyStyle: { padding: 16 }, children: [_jsx("div", { style: { marginBottom: 8 }, className: "turingo-secondary", children: strings.formatString(strings.screens.evaluation.quiz.progress, index + 1, (_m = (_l = quiz.pages) === null || _l === void 0 ? void 0 : _l[0]) === null || _m === void 0 ? void 0 : _m.questions.length) }), _jsx(Form.Item, { className: "quiz-form-item", requiredMark: false, required: false, style: { marginBottom: 0, width: '100%' }, name: [question.id], label: label, rules: [{ required: true, message: '' }], children: _jsx(Radio.Group, { style: { width: '100%' }, children: (_o = question === null || question === void 0 ? void 0 : question.alternatives) === null || _o === void 0 ? void 0 : _o.map((alternative, alternativeIndex) => {
                                    var _a;
                                    return (_jsx("div", { style: {
                                            display: 'flex',
                                            alignItems: 'flex-start',
                                            marginBottom: alternativeIndex !== ((_a = question === null || question === void 0 ? void 0 : question.alternatives) === null || _a === void 0 ? void 0 : _a.length) - 1 ? 8 : 0,
                                            width: '100%',
                                        }, children: _jsx(Radio, { value: alternative.id, style: {
                                                marginRight: '8px',
                                            }, children: _jsx(Paragraph, { className: "turingo-text", children: alternative.title }) }) }, alternativeIndex));
                                }) }) })] }), _jsx(Paragraph, { style: { color: 'red', transition: 'all 0.3s' }, children: hasError ? strings.screens.evaluation.quiz.error.empty : '' })] }));
    }
};
const QuizAnswerForm = (props) => {
    var _a, _b, _c;
    const { quiz, onSubmit, quizComplete } = props;
    const [form] = Form.useForm();
    const [submitError, setSubmitError] = useState();
    const [error, setError] = useState([]);
    const onClick = () => {
        form.validateFields()
            .then((values) => {
            onSubmit(values);
        })
            .catch((errors) => {
            setError(errors.errorFields);
            setSubmitError(true);
        });
    };
    const showResults = quiz.options.showResults;
    return (_jsxs("div", { className: "lateral-padding", children: [!quizComplete && (_jsxs(Card, { bodyStyle: { padding: 16 }, style: { marginBottom: 16 }, children: [_jsx("div", { className: "turingo-title", style: { marginBottom: 8 }, children: quiz.title }), _jsx("div", { className: "turingo-text", children: quiz.description })] })), (!quizComplete || showResults) && (_jsxs(Form, { layout: "vertical", form: form, style: { marginBottom: 16 }, disabled: quizComplete, children: [(_c = (_b = (_a = quiz.pages) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.questions) === null || _c === void 0 ? void 0 : _c.map((question, index) => {
                        return (_jsx(QuestionFormItem, { errors: { get: error, set: setError }, submitError: { get: submitError, set: setSubmitError }, quizComplete: quizComplete, question: question, index: index, quiz: quiz }, question.id));
                    }), submitError && (_jsx(Alert, { showIcon: true, type: "error", message: strings.screens.evaluation.quiz.error.help1, style: {
                            borderRadius: 4,
                            marginBottom: 16,
                        } })), !quizComplete && (_jsx(Row, { justify: "end", children: _jsx(TuringoButton, { type: "primary", expandInMobile: true, onClick: onClick, children: strings.general.continue }) }))] }))] }));
};
export { QuizAnswerForm };
